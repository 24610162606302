import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from '../../assets/LargeKenzieLogo.png';
// import PlacementContact from '../../components/Timeline/PlacementContact';
import AdvisorCard from "../Timeline/AdvisorCard";
import ReactGA from 'react-ga';
const useStyles = makeStyles(theme => ({
    welcomeContainer: {
        // backgroundColor: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%',
        // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
        padding: '0px 30px 0px 30px',
        [theme.breakpoints.up('700')]: {
            // marginLeft: '8.33vw',
            maxWidth: '75vw'
        },
        [theme.breakpoints.up('xl')]: {
            // marginLeft: '160px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '5px 10px 0px 10px',
            width: '90vw',
            minWidth: '300px',
            // marginLeft: 'auto',
            // marginRight: 'auto'
        },
        [theme.breakpoints.down('419')]: {
            width: '93vw'
        }
    },

    logoTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    logo: {
        width: '50px',
        height: '50px',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '35px',
            height: '35px',
            marginRight: '10px',
        },
        [theme.breakpoints.down('515')]: {
            width: '30px',
            height: '30px',
            marginRight: '10px',
        }
    },

    textContainer: {
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // justifyContent: 'space-between',
        // marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft:7
        }
    },

    title: {
        fontFamily: 'Roboto Slab',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '40px',
        color: theme.palette.primary.white,
        // whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('1300')]: {
            fontSize:'26px'
        },
        [theme.breakpoints.down('1145')]: {
            fontSize:'24px'
        },
        [theme.breakpoints.down('700')]: {
            fontSize:'24px'
        },
        [theme.breakpoints.down('515')]: {
            fontSize: '17px',
        }
    },

    subtitle: {
        fontSize: '20px',
        color: theme.palette.primary.white,
        lineHeight: '23.48px',
        marginTop: '20px',
        // paddingBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            paddingBottom: '5px',
        },
        "& strong": {
            color: theme.palette.primary.green
        }
    },

    advisorContainer: {
        width: '100%',
        height: '100%',
    },
    advisorinfo: {
        width: '100%',
        height: '20px',
    },
    currentStepTitle: {
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        lineHeight: '20px',
        // marginRight: 4
    },
    currentStep: {
        // marginLeft: 4,
        lineHeight: '20px',
        [theme.breakpoints.down('780')]: {
            width: '80%',
        },
        [theme.breakpoints.down('620')]: {
            width: '70%',
        }
    },
    boldDate: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
    },
}))

function Welcome(props) {
    const classes = useStyles();
    let { userName, progressTo, advisorName, advisorEmail } = props.data
    const error = props.error

    const getStep = (progressTo) => {
        switch(progressTo){
            case 1:
                return '1. Request info'
            case 2:
                return '2. Admissions interview'
            case 3:
                return '3. Skills check'
            case 4:
                return '4. Financial aid call'
            case 5:
                return '5. Loan application'
            case 6:
                return '6. Student contract'
            case 7:
                return '7. Pay desposit/invoice'
            case 8:
                return '8. Enrollment complete'
            default:
                return ""
        }
    }
    
    useEffect(() => {
        if (typeof window !== "undefined" && process.env.REACT_APP_ENVIRONMENT === "production") {
            ReactGA.set({dimension1: getStep(progressTo)})
            ReactGA.pageview(window.location.pathname);
        }
    });

    return (
        <React.Fragment>
            <div className={classes.welcomeContainer}>
                    <div className={classes.logoTextContainer}>
                        <div className={classes.logoContainer}>
                            <img className={classes.logo} src={logo} alt='Kenzie Academy' />
                        </div>
                        <div className={classes.textContainer}>
                            <h1 className={classes.title}>Your Kenzie Admissions Journey</h1>
                            {!error && <p className={classes.subtitle}>Welcome, {userName}!{progressTo && <> You are ready for <strong>Step {progressTo}</strong></>}</p>}
                            {error && <p className={classes.subtitle}>We’re sorry! There seems to be something wrong. Reach out to your advisor to let them know.</p>}
                        </div>
                    </div>
                    <AdvisorCard advisorName={advisorName} advisorEmail={advisorEmail} error={error}/>
            </div>

        </React.Fragment>
    )
}

export default Welcome