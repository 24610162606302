import React from "react"
const ADMISSIONS_STEPS = {
    '1': {
        stepNumber: 1,
        title: 'Requested Information - Software Engineering',
        description: 'Congratulations on taking the first step towards a new career in tech. We’re so happy you’re here!',
        key: 'requestInfo'
    },
    '2': (advisorEmail) => ({
        stepNumber: 2,
        title: 'Schedule and Complete Your Admissions Interview',
        description: <>During this 25-minute conversation, we will have the opportunity to learn more about you and your career goals. We will go over Kenzie’s programs, and ask each other some questions. Let’s turn those goals into reality! If you still haven’t booked time with your advisor you can do that <a href={`mailto:${advisorEmail}`}>here</a>.</>,
        key: 'admissionsInterview'
    }),
    '3': {
        stepNumber: 3,
        title:'Complete Your Skills Check',
        description:'We want to make sure every student we accept is set up for success and that starts with the Skills Check. You have 5 business days to complete it, so we know you’re serious about the program. It will take an hour max, and you need to complete it on a computer (not a phone). You got this! ',
        key: 'skillsCheck'
    },
    '4': {
        stepNumber: 4,
        title: 'Schedule and Complete Your Financial Aid Call',
        description: 'We know how important it is to find the right solution when it comes to financing your education and want to provide you with as many options as possible! During this call we will review and go over which option is best for you.',
        key: 'faCall'
    },
    '5': {
        stepNumber:5,
        title:'Select Your Financing',
        description:'Fund your education with affordable and flexible options. Choose the plan that works best for you.',
        key: 'funding'
    },
    '6': {
        stepNumber:6,
        title:'Sign Your Enrollment Agreement',
        description:'You’re very close now! It’s time to sign your enrollment agreement.',
        key: 'studentContract'
    },
    '7': {
        stepNumber:7,
        title:'Pay Your Program Deposit',
        description:'Time to pay your program deposit. You’ll receive an invoice, and this will be due in order to be fully enrolled. ',
        key: 'payProgram'
    },
    '8': {
        stepNumber:8,
        title:'You’re officially enrolled! Welcome to Kenzie!',
        description:'We are excited to welcome you to the Kenzie family. Keep an eye out for an email from your Orientation Specialist for how you can get Kenzie Ready!',
        key: 'enrolled'
    },
    '9': {
        stepNumber: false,
        title:'Time to get prepared!',
        description:'You’ll receive an email shortly that will grant you access to the Kenzie Launchpad. This course is designed to prepare you for your Kenzie program. Be sure to complete it before your first day of class.'
    }
}

export const ADMISSIONS_STEPS_ARR = Object.values(ADMISSIONS_STEPS)
const paymentOptionsSeven = {
    "24-MONTH-PLAN": {
        title: 'Pay Your Program Deposit',
        description: 'Time to pay your program deposit. You’ll receive an invoice, and this will be due in order to be fully enrolled. '
    },
    "12-MONTH-PLAN": {
        title: 'Pay Your Program Deposit',
        description: 'Time to pay your program deposit. You’ll receive an invoice, and this will be due in order to be fully enrolled. '
    },
    "FULL-TUITION": {
        title: 'Pay Your Tuition Invoice',
        description: 'Time to square up your payment. You’ll receive and invoice in your email shortly with instructions on your next steps.'
    },
    "LOAN": {
        title: 'Pay Your Program Deposit or Invoice',
        description: 'Time to pay your program deposit or invoice. Which document you recieve is dependent on the loan option you’ve selected. It will be sent to your email. This will be due in order to be fully enrolled.'
    },
    "UNKNOWN": {
        title: 'Pay Your Program Deposit or Invoice',
        description: 'Time to pay your program deposit or invoice. Which document you recieve is dependent on the loan option you’ve selected. It will be sent to your email. This will be due in order to be fully enrolled.'
    }
}

export const getPaymentSteps = (paymentTerms) => {
    const PAYMENT_OPTIONS = ["24-MONTH-PLAN", "24-MONTH-DEFERRED-PLAN", "12-MONTH-PLAN", "FULL-TUITION", "LOAN", "UNKNOWN"]
    let sevenData = {}
    if (PAYMENT_OPTIONS.includes(paymentTerms)){
        sevenData = paymentOptionsSeven[paymentTerms]
    }else {
        sevenData = {title: ADMISSIONS_STEPS['7'].title, description: ADMISSIONS_STEPS[7].description}
    }
    return {
        // stepFive: {stepNumber: ADMISSIONS_STEPS['5'].stepNumber, ...fiveData},
        // stepSeven: {stepNumber: ADMISSIONS_STEPS['7'].stepNumber, ...sevenData}
        stepSeven: {...ADMISSIONS_STEPS['7'], ...sevenData}
    }
}