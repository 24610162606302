import React, {useState} from 'react'
import { makeStyles } from "@material-ui/core/styles";
// import {useMediaQuery} from "@material-ui/core"
import { Done } from "@material-ui/icons"
import { stepStates } from "./helpers"
import {Collapse, IconButton} from "@material-ui/core"
import dropdown from "../../assets/dropdown_arrow.svg"

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: '0 auto',
        marginBottom: 20,
        width: '75%',
        boxShadow: "0px 2px 10px #9E9E9E",
        borderRadius: '10px',
        [theme.breakpoints.down('1120')]: {
            width: '85%'
        },
        [theme.breakpoints.down('950')]: {
            width: '95%'
        },
        [theme.breakpoints.down('715')]: {
            width: '98%'
        }
    },
    wrapperClear: {
        boxShadow: '0px 0px 0px #FFFFFF'
    },
    topRow: {
        display: 'flex',
        justifyContent:'flex-start',
        alignItems: 'center',
        borderRadius: '10px 10px 0px 0px',
        padding: '12px 20px',
        [theme.breakpoints.down('sm')]: {
            padding: '11px'
        }
    },
    topRowCurrent: {
        backgroundColor: theme.palette.primary.green
    },
    circle: {
        // [theme.breakpoints.up('md')]: {
        //     width: '35px',
        //     height: '35px',
        //     // marginLeft:'auto',
        //     marginRight:'10px',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: '30px',
        //     height: '30px',
        // },
        background: '#FFFFFF',
        width: '32px',
        height: '32px',
        borderRadius: '45px',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '18px',
            height: '18px'
        }
    },
    circleDone: {
        background:'#95CEBE'
    },
    circleCollapse: {
        width: '32px',
        height: '32px',
        background: '#E5E1DF',
        borderRadius: '45px',
        border: 'none',
        outline: 'none',
    },
    collapseImg: {
        width: 8,
        height: 8,
        transition: 'transform .5s'
    },
    collapseImgClosed: {
        transform: 'rotate(-90deg)'
    },
    collapseImgCurrent: {
        opacity: '50%'
    },
    icon: {
        width: '16px',
        height: '16px',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 12,
            height: 12,
        }
    },
    numberText: {
        fontFamily: 'Arial',
        fontWeight: '400',
        margin:'auto',
        lineHeight: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        }
    },
    titleContainer: {
        flexGrow: 3,
        paddingLeft: '20px',
        paddingRight: '10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '10px'
        },
    },
    title: {
        fontSize: '20px',
        // marginBottom: '5px',
        // width: '100%',
        [theme.breakpoints.up('md')]: {
            // marginTop: 7,
        },
        [theme.breakpoints.down('800')]: {
            fontSize: '15px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        },
        padding: 0,
        lineHeight: '18.78px'
    },
    clearWrapperTitle: {
        paddingLeft: '32px'
    },
    titleCompleted: {
        opacity: '0.5'
    },
    description: {
        marginTop: 9,
        padding: '0px 72px',
        lineHeight: '25px',
        '& a:link': {
            color: theme.palette.primary.black,
            // fontWeight: 'bold'
        },
        '& a:visited': {
            color: theme.palette.primary.black,
            // fontWeight: 'bold'
        },
        '& a:hover': {
            color: theme.palette.primary.green,
            // fontWeight: 'bold'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0px 38px',
            lineHeight: '18px'
        },
    },
    descriptionWrap: {
        paddingBottom: 20
    },
    status: {
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '19px',
        marginTop: 18,
        padding: '0px 72px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
            padding: '0px 38px',
            lineHeight: '16px'
        }
    },
    boldDate: {
        fontFamily: 'Arial',
        fontWeight: 'bold',
    },
    content: {
        // paddingLeft: 15,
        // paddingRight: '0 2.5vw'
    }
}))

const Card = props => {
    const classes = useStyles()
    
    // const theme = useTheme();
    // const desktop = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);

    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
    };
    const {lastStep, stepState} = props
    let stepNumber, title, description = null
    if(props.data){
        stepNumber = props.data.stepNumber
        title = props.data.title
        description = props.data.description
    }

    let numberElem = null
    let circleClass = null
    if(stepState === stepStates.completed){
        numberElem = <Done className={classes.icon}/>
        circleClass = classes.circleDone
    }else if (stepState === stepStates.current){
        numberElem = <h2 className={classes.numberText}>{stepNumber}</h2>
        circleClass = classes.circleCurrent
    } else if(stepNumber){
        numberElem = <h2 className={classes.numberText}>{stepNumber}</h2>
    }

    const [cardState, setCardState] = useState(stepState === stepStates.completed ? false : true)
    if(!title && !description){
        return <div className={classes.wrapper}>
            <div className={classes.leftCol}>
                {numberElem &&
                    <div className={`${classes.circle} ${circleClass}`}>
                        {numberElem}
                    </div>
                }
                {(numberElem && !lastStep) &&
                    <div className={`${classes.progress} ${(stepState === stepStates.completed) ? classes.progressDone : classes.progressPending}`}/>
                }
            </div>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>
    }
    return <div className={`${classes.wrapper} ${props.clearWrapper ? classes.wrapperClear : null}`}>
        
        <div className={classes.content}>
            <div className={`${classes.topRow} ${(stepState === stepStates.current) ? classes.topRowCurrent : null}`}>
                {numberElem &&
                    <div className={`${classes.circle} ${circleClass}`}>
                        {numberElem}
                    </div>
                }
                <div className={classes.titleContainer}
                    ><h2 className={`${classes.title} ${stepState === stepStates.completed ? classes.titleCompleted : null} ${props.clearWrapper ? classes.clearWrapperTitle : null}`}>{title}</h2>
                </div>
                <div>
                    {!props.clearWrapper && <IconButton className={classes.circleCollapse} onClick={(e) => setCardState(!cardState)} disabled={stepState === stepStates.current}><img src={dropdown} alt="Drop down arrow" className={`${classes.collapseImg} ${!cardState ? classes.collapseImgClosed : null} ${stepState === stepStates.current ? classes.collapseImgCurrent : null}`}></img></IconButton>}
                </div>
            </div>
            <Collapse in={cardState}>
                <div className={classes.descriptionWrap}>
                    <p className={classes.description}>{description}</p>
                    {(props.stepStatus && props.stepStatus.status) && 
                        <p className={classes.status}>
                            Status: {props.stepStatus.status} 
                            <span className={classes.boldDate}> {props.stepStatus.date && props.stepStatus.date !== '' ? new Date(props.stepStatus.date).toLocaleString('en-us',options) : ''}</span>
                        </p>
                    }
                </div>
            </Collapse>
        </div>
    </div>
}

export default Card
